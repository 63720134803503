<template>
  <div class="pt-60 pb-24">

    <div class="container mx-auto">
      <h1 class="custom-heading h-custom-color">Podmienky ochrany súkromia</h1>
      <p class="p-custom-color custom-paragraph mr-20">
        Posledná aktualizácia 1. augusta 2021
      </p>
      <p class="mt-20">
        Hlavnou prioritou HostNow, dostupnej na stránke hostnow.cz, je súkromie použivateľov.
        Tento dokument obsahuje informácie o tom, aké informácie zbierame cez stránku hostnow.cz
        a ako tieto informácie následne používame.
        <br><br>
        Pokiaľ máte akékoľvek otázky ktoré nie su objasnené na tejto stránke, neváhajte nás
        kontaktovať na našej emailovej adrese info@hostnow.cz
      </p>

      <br>

      <h1 class="custom-heading h-custom-color">Súhlas</h1>
      <ol class="list-decimal">
        <li class="ml-10">Používaním tejto stránky a registráciou na našej stránke súhlasíte s našimi podmienkami o ochrane súkromia.</li>
      </ol>

      <br>

      <h1 class="custom-heading h-custom-color">Informácie, ktoré zbierame</h1>
      <ol class="list-decimal">
        <li class="ml-10">
          Osobné údaje, ktoré od Vás žiadame, a dôvody, pre ktoré od Vás tieto informácie žiadame,
          budú objasnené v čase keď od Vás tieto údaje budeme žiadať.
        </li>
        <li class="ml-10">
          Pokiaľ nás kontaktujete priamo, obdržíme Vaše osobné údaje ako Vaše meno,
          emailovú adresu, telefónne číslo, obsah správy a/alebo akékoľvek prílohy, ktoré k správe priložíte.
        </li>
        <li class="ml-10">
          Pri registrácii Vášho účtu na našej stránke od Vás môžeme žiadať informácie ako Vaše meno, emailovú adresu a telefónne číslo.
        </li>
        <li class="ml-10">
          Pri registrácií a prihlasovaní na stránku uchovávame záznamy o vašej IP adrese,
          z ktorej sa prihlasujete/registrujete aby sme dokázali predísť podvodom a pri nelegálnej činnosti vyviesť zodpovednosť.
        </li>
      </ol>

      <br>

      <h1 class="custom-heading h-custom-color">Ako používame vaše informácie</h1>
      <p class="my-3">Vaše informácie používame na rôzne činnosti, vrátane:</p>
      <ol class="list-decimal">
        <li class="ml-10">
          Na poskytovanie, spravovanie a ovládanie našej webovej stránky.
        </li>
        <li class="ml-10">
          Na zlepšenie, upravovanie a rožírenie našej webovej stránky.
        </li>
        <li class="ml-10">
          Na vývoj nových produktov, služieb, súčastí a funkcií.
        </li>
        <li class="ml-10">
          Na komunikáciu s vami. Buď priamo alebo cez jedného z našich partnerov, vrátane zákazníckeho
          servisu a na posielanie Vám aktualizácií a iných informácií, ktoré súvisia s našou webovou stránkou,
          taktiež aj na marketingové účely.
        </li>
        <li class="ml-10">
          Na posielanie vám emailov.
        </li>
        <li class="ml-10">
          Na prevenciu a odhalenie porušenia pravidiel.
        </li>
      </ol>

      <br>

      <h1 class="custom-heading h-custom-color">GDPR Práva na ochranu dát</h1>
      <p class="my-3">Chceli by sme si byť istí, že každý náš použivateľ je plne oboznámený so svojimi právami na ochranu dát. Každý použivateľ má právo na nasledovné:</p>
      <ol class="list-decimal">
        <li class="ml-10">
          Právo prístupu - Máte právo požiadať o kópiu svojich osobných údajov. Za túto službu si môžeme účtovať malý poplatok
        </li>
        <li class="ml-10">
          Usmerňovacie právo - Máte právo žiadať nápravu akýchkoľvek informácií, ktoré si myslíte, že sú nesprávne.
          Taktiež máte právo požiadať o doplnenie informácií, ktoré si myslíte, že nie sú komplétne.
        </li>
        <li class="ml-10">
          Právo na vymazanie - Máte právo požiadať o vymazanie vašich osobných údajov pod určitými podmienkami
        </li>
        <li class="ml-10">
          Právo na obmedzenie spracovávania - Máte právo na požiadanie o obmedzenie spracovávania Vašich osobných údajov pod určitými podmienkami
        </li>
        <li class="ml-10">
          Právo namietať na spracovávanie - Máte právo namietať na spracovávanie Vašich osobných údajov pod určitými podmienkami
        </li>
        <li class="ml-10">
          Pokiaľ máte akúkoľvek žiadosť, máme mesiac na jej zodpovedanie. Pokiaľ by ste chceli uplatniť ktorékoľvek z týchto práv, prosím kontaktujte nás.
        </li>
      </ol>

      <br>

      <h1 class="custom-heading h-custom-color">Cookies a Web Beacons</h1>
      <p class="mt-3">Ako každá iná webová stránka, hostnow.cz používa cookies. Tieto cookies používame na ukladane informácií
        vrátane Vašich preferencií a stránok, ktoré ste navštívili. Tieto informácie sú požité na zlepšovanie použivateľskej
        skúsenosti s našou stránkou na základe údajov o type prehliadača a iných údajoch našich návštevníkov</p>

      <br>

      <h1 class="custom-heading h-custom-color">Cookies a Web Beacons</h1>
      <p class="mt-3">Under the CCPA, among other rights, California consumers have the right to:Request that a
        business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a
        business has collected about consumers.Request that a business delete any personal data about the consumer that a business
        has collected.Request that a business that sells a consumer's personal data, not sell the consumer's personal data.If you make a request,
        we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

      <br>

      <h1 class="custom-heading h-custom-color">Informácie o deťoch</h1>
      <p class="mt-3">Našou ďalšou prioritou je ochrana Vašich detí kým používajú internet. Vyzývame rodičov a
        zákonných zástupcov aby preskúmali, zúčastnili sa v, a/alebo monitorovali a viedli svoje deti v ich online aktivitách
        HostNow.cz nezbiera vedome žiadne osobné údaje od detí, ktoré majú menej ako 13 rokov. Pokiaľ si myslíte,
        že Vaše dieťa nám poskytlo tieto informácie, silne Vás vyzývame aby ste nás okamžite kontaktovali a
        urobíme čo bude v našich silách aby sme ihneď odstránili tieto informácie z našich záznamov.</p>



    </div>

  </div>
</template>

<script>

export default {
  name: "GDPR",
  components: {
  },
  mounted () {
    window.scrollTo(0, 0)
  },

  metaInfo() {
    return {title: "Podmienky ochrany súkromia"}
  },
}
</script>

<style scoped>

</style>